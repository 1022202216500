import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Box = ({ index, activeIndex, onClick, onMouseEnter, onMouseLeave, data }) => {
  
  const isLarge = activeIndex === index;
  const boxWidth = isLarge ? 'w-2/5 max-lg:w-auto rounded-lg transition-all duration-1000 transform' : 'w-48 max-lg:w-auto max-lg:h-auto rounded-lg transition-all duration-1000 transform';
  const textContainerClasses = isLarge ? 'text-white py-20 px-10 bg-transparent opacity-100 transition-all duration-1000 transform' : 'text-white opacity-0 transition-all duration-1000 transform';

  const { backgroundImage, heading, paragraph, closedImage, closedHeading } = data[index];

  return (
    <div
      className={`relative p-4 m-4 cursor-pointer ${boxWidth}`}
      onClick={() => onClick(index)}
      onMouseEnter={() => onMouseEnter(index)}
      onMouseLeave={() => onMouseLeave(index)}
      style={{ 
        backgroundImage: `url(${backgroundImage})`, 
        backgroundSize: 'cover',
      }}
    >
      {!isLarge && (
        <div className="absolute top-0 left-0 right-0 p-4  text-white ">
          <h2 className="text-xl font-bold">{closedHeading}</h2>
        </div>
      )}
      <div className={textContainerClasses}>
        <h2 className="text-xl font-bold mb-2">{isLarge ? heading : closedHeading}</h2>
        {isLarge && (
          <>
            <p className="mb-4">{paragraph}</p>
            <a href="#" className="inline-flex">
              <Link
                to="/contact"
                className="flex relative transition-all duration-300 transform items-center"
              >
                <span>Connect For More</span>
                <FaArrowRight className="ml-2 hover:ml-6 transition-all duration-1000 transform" />
              </Link>
            </a>
          </>
        )}
      </div>
    </div>
  );
};
export default Box;
