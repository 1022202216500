import React, { useEffect, useRef, useState } from "react";
import {
  FaAdn,
  FaCheck,
  FaChevronDown,
  FaChevronRight,
  FaChevronUp,
} from "react-icons/fa";
import StickyBox from "react-sticky-box";

const productData = [
  {
    category: "AI and machine learning",
    description: [
      "Build, deploy, and scale more effective models with our fully managed AI platform, Vertex AI—enhanced by Gemini",
      "Build generative AI applications quickly and responsibly—powered by Google’s most advanced technology and ecosystem of AI partners",
      "Improve customer service with Contact Center AI’s virtual agents and conversational AI products like Speech-to-Text",
      "Get AI-powered code generation, recommendations, and completion across Google Cloud products from Duet AI",
    ],
  },
  {
    category: "Compute",
    description: [
      "Create and run customizable virtual machines with Compute Engine. For scale-out workloads, Tau VMs offer 42% better price performance over comparable cloud offerings.",
      "Automatically deploy, scale, and manage containers with Google Kubernetes Engine or Cloud Run",
    ],
  },
  {
    category: "Storage",
    description: [
      "Store any type of data, any amount of data, and retrieve it as often as you’d like with object storage",
      "Transfer data with online and offline transfer solutions, including Storage Transfer Service and Transfer Appliance",
      "Persistent Disk block storage is fully integrated with Google Cloud products like Compute Engine and GKE",
    ],
  },
  {
    category: "Databases",
    description: [
      "Reduce MySQL, PostgreSQL, and SQL Server costs with Cloud SQL, a fully-managed database service",
      "Scale your enterprise workloads and build generative AI apps with AlloyDB for PostgreSQL",
      "Get high performance, a 99.999% availability SLA, and virtually unlimited scale with Spanner",
    ],
  },
  {
    category: "Data analytics",
    description: [
      "Run analytics at scale with 26%–34% lower three-year TCO on BigQuery compared to cloud data warehouse alternatives",
      "Ingest, process, and analyze event streams in real time to make data more useful",
      "Reveal the true power of your data and bring clarity to every situation with Looker and Google Cloud",
    ],
  },
  {
    category: "Networking",
    description: [
      "Help protect your applications and websites against denial of service and web attacks with Cloud Armor",
      "Quickly and securely scale web and video content delivery with Cloud CDN",
      "Explore hybrid connectivity options, including VPN, peering, and enterprise support",
    ],
  },
  {
    category: "Developer tools",
    description: [
      "Write, debug, and run cloud-native applications, locally or in the cloud—quickly and easily with Cloud Code",
      "With Cloud Build, continuously build, test, and deploy software across all languages and in multiple environments",
      "Deploy pre-built solution templates—with an active Google Cloud account—including dynamic websites, load balanced VMs, and three-tier web apps.",
    ],
  },
  {
    category: "Security",
    description: [
      "Understand threat actors and potential attack vectors with Mandiant Threat Intelligence and Mandiant Consulting Services",
      "Detect, investigate, and respond to threats faster with Chronicle Security Operations",
      "Protect your workloads and meet digital sovereignty requirements with our full suite of security products and services",
    ],
  },
];

const ProductSection = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isSticky, setIsSticky] = useState(false);

  const leftSideRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const offsetTop = leftSideRef.current?.offsetTop || 0;
      setIsSticky(window.scrollY > offsetTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="flex flex-col lg:flex-row lg:justify-between p-4 lg:p-16 font-sans">
      {!isSticky ? (
        <StickyBox offsetTop={20} offsetBottom={20}>
          <div className="ml-10 max-lg:ml-1 transform transition-all duration-1000 ease-in-out">
            <h2 className="text-4xl font-bold mb-4">
              Build with the cloud platform{" "}
              <span className="text-[#38B6FF] ">
                designed for developers and AI
              </span>
            </h2>
            <p className="text-xl w-[570px] max-lg:w-auto">
              Try Google Cloud with free usage of over 20 products, plus new
              customers get $300 in free credits on signup.
            </p>
            <div className="mt-4">
              <button className="bg-gradient-to-br bg-[#38B6FF] text-white py-4 px-4 max-md:mb-4 mr-2 rounded">
                Get started for free
              </button>
            </div>
          </div>
        </StickyBox>
      ) : (
        <div className="ml-10 transform transition-all duration-1000 ease-in-out">
          <StickyBox>
            <h2 className="text-4xl font-bold mb-4">
              Build with the cloud platform{" "}
              <span className="bg-[#38B6FF] bg-clip-text text-transparent">
                designed for developers and AI
              </span>
            </h2>
            <p className="text-xl w-[570px] max-lg:w-auto">
              Try Google Cloud with free usage of over 20 products, plus new
              customers get $300 in free credits on signup.
            </p>
            <div className="mt-4">
              <button className="bg-[#38B6FF] text-white py-4 px-4 max-md:mb-4 mr-2 rounded">
                Get started for free
              </button>
            </div>
          </StickyBox>
        </div>
      )}

      <div className="lg:w-4/5 lg:pl-4 lg:px-8 mt-4 lg:mt-0">
        {productData.map((product, index) => (
          <div key={index}>
            <div
              className={`flex items-center justify-between cursor-pointer border-b-2 py-4 ${
                selectedCategory === index ? "open" : ""
              } transition-all duration-1000`}
              onClick={() =>
                setSelectedCategory(selectedCategory === index ? null : index)
              }
            >
              <h4 className="text-2xl font-bold mb-1 bg-[#38B6FF] bg-clip-text text-transparent">
                {product.category}
              </h4>
              {selectedCategory === index ? (
                <FaChevronUp className="text-blue-400 text-2xl" />
              ) : (
                <FaChevronDown className="text-blue-400 text-2xl" />
              )}
            </div>
            <div
              className={`transition-all duration-300 ${
                selectedCategory === index
                  ? "max-h-screen opacity-100 mb-4 border-b-2"
                  : "max-h-screen opacity-0"
              } overflow-hidden`}
            >
              {selectedCategory === index && (
                <div>
                  {product.description.map((desc, descIndex) => (
                    <div key={descIndex} className="flex items-center my-8">
                      <FaCheck
                        className="text-[#38B6FF] text-xl mr-2"
                        size={30}
                      />{" "}
                      {/* Tick icon */}
                      <p className="text-lg flex">{desc}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductSection;
