import React from 'react';

const Boxses = () => {
  return (
    <div className="flex justify-center items-center max-lg:flex-col px-28 max-lg:px-2">
      <div className="w-1/2 max-lg:w-auto h-auto max-lg:h-auto rounded-lg bg-gradient-to-br from-green-500 to-blue-500 p-8 m-4 hover:rounded-3xl transition-all duration-500">
        <p className='text-2xl font-bold max-lg:text-xl text-white font-sans'>
          Try Google Cloud with free usage of over 20 products, plus new customers get $300 in free credits on signup.
        </p>
        <button className="mt-14 max-lg:mt-4 bg-white text-blue-600 py-2 px-4 rounded">Get started for free</button>
      </div>

      <div className="w-1/2 max-lg:w-auto h-auto max-lg:h-auto rounded-lg bg-gradient-to-br from-blue-300 to-blue-700 p-8 m-4 hover:rounded-3xl transition-all duration-500">
        <p className='text-2xl max-lg:text-xl font-bold text-white font-sans'>
          Build and scale your startup with your first year covered by Google Cloud credits, plus get support to help your startup grow.
        </p>
        <button className="mt-14 max-lg:mt-4 bg-white max-lg:text-sm text-blue-600 py-2 px-4 max-lg:px-2 rounded">Learn more about the startup program</button>
      </div>
      <div>
        
      </div>
    </div>
  );
};

export default Boxses;
