import React, { useState } from 'react';
import Box from './Box';

const AdvanceBoxes = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleBoxClick = (index) => {
    setActiveIndex(index);
  };

  const handleMouseEnter = (index) => {
    if (activeIndex !== index) {
      setActiveIndex(index);
    }
  };

  const handleMouseLeave = () => {
    setActiveIndex(0);
  };

  const data = [
    {
      backgroundImage: 'https://img.freepik.com/premium-vector/internet-things-iot-concept-big-data-cloud-computing-network-physical-devices-with-secure-network-connectivity-dark-blue-color-background_43778-618.jpg',
      heading: 'Internet of Things (IoT)',
      paragraph: 'Enabling businesses to harness the potential of IoT, connecting/controlling devices, and collecting data.',
      closedImage: 'https://via.placeholder.com/500x300', 
      closedHeading: 'Internet of Things (IoT)',
    },
    {
      backgroundImage: 'https://cdn.sanity.io/images/tlr8oxjg/production/afce27d5dcb021422c709cb5bf60cb3a33f7376d-1456x816.png?w=1080&q=100&fit=clip&auto=format',
      heading: 'Artificial Intelligence',
      paragraph: 'Utilize AI/ML technology to elevate the apps and systems performance based on our analytical insights.',
      closedImage: 'https://via.placeholder.com/500x300',
      closedHeading: 'Artificial Intelligence',
    },
    {
      backgroundImage: 'https://cdn.sanity.io/images/tlr8oxjg/production/2d8450596e26adafc8e47e88a65bce42104b732a-1456x816.png?w=1080&q=100&fit=clip&auto=format',
      heading: 'Data & Analytics',
      paragraph: 'Drive insights and refine strategies. Utilize the power of data for informed decisions and growth through our expertise in Data and analytics.',
      closedImage: 'https://via.placeholder.com/500x300',
      closedHeading: 'Data & Analytics',
    },
    {
      backgroundImage: 'https://www.patriotsoftware.com/wp-content/uploads/2022/01/what-is-blockchain-1.jpg',
      heading: 'Blockchain',
      paragraph: 'Choose the decentralized approaches to leverage transparency and immutability in data management through blockchain technology.',
      closedImage: 'https://via.placeholder.com/500x300', 
      closedHeading: 'Blockchain',
    },
  ];

  return (
    <div className="flex flex-col items-center ">
      <div className="text-center mt-10">
        <h1 className="text-3xl font-bold mb-4">Our Advanced Services</h1>
        <p className='text-xl'>Unravel the power of our Next-Gen IT services in favor of your exclusive business mission.</p>
      </div>
      <div className="flex max-lg:flex-col justify-center mt-10 h-[400px] max-lg:h-full">
        {data.map((item, index) => (
          <Box
            key={index}
            index={index}
            activeIndex={activeIndex}
            onClick={handleBoxClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            data={data}
          />
        ))}
      </div>
    </div>
  );
};

export default AdvanceBoxes;
