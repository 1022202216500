import React from 'react';

const VideoPopupreplace = ({ onClose, videoUrl }) => {
  return (
    <div className="fixed z-10 inset-0 bg-black bg-opacity-50 flex items-center justify-center shadow-2xl">
      <div className="relative w-5/6 h-5/6 max-w-4xl max-h-screen overflow-hidden bg-white rounded-lg shadow-xl p-6">
        {/* Your video player iframe */}
        <iframe
          width="100%"
          height="100%"
          src={videoUrl}
          title="Google Cloud: The new way to cloud"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="rounded-lg overflow-hidden"
        ></iframe>
        <button
          onClick={onClose}
          className="absolute top-0 right-0  text-black text-4xl cursor-pointer"
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default VideoPopupreplace;
