import React from 'react'

function Aboutus() {
  return (
    <div>
<section className="flex items-center  py-10   xl:h-screen font-poppins  ">
  <div className="justify-center  flex-1 max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
    <div className="flex flex-wrap ">
      <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0">
        <div className="relative">
          <img
            src="https://i.postimg.cc/QtyYkbxp/pexels-andrea-piacquadio-927022.jpg"
            alt=""
            className="relative z-40 object-cover w-full h-96 lg:rounded-tr-[80px] lg:rounded-bl-[80px] rounded"
          />
          <div className="absolute z-10 hidden w-full h-full bg-[#38B6FF] rounded-bl-[80px] rounded -bottom-6 right-6 lg:block"></div>
          <div className="absolute z-50 text-[#38B6FF] transform -translate-y-1/2 cursor-pointer top-1/2 left-[46%] hover:text-blue-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="w-14 h-14 bi bi-play-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"></path>
            </svg>
          </div>
        </div>
      </div>
      <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0 ">
        <div className="relative">
          <h1 className="absolute -top-20   left-0 text-[20px] lg:text-[100px]  opacity-5 md:block hidden">
            About Us
          </h1>
          <h1 className="pl-2 text-3xl font-bold border-l-8 border-blue-400 md:text-5xl dark:text-white">
            Welcome to our site
          </h1>
        </div>
        <p className="mt-6 mb-10 text-base leading-7  dark:text-gray-400">
        Welcome to Peron Cloud, where we specialize in delivering seamless cloud integration solutions tailored to your unique business needs. Our mission is to simplify your digital journey, offering expertise and tools to streamline operations, enhance collaboration, and ensure the security of your data. With a focus on scalability, user-friendly interfaces, and dedicated support, Peron Cloud is your trusted partner for a connected, efficient, and secure cloud environment. Join us in unlocking the full potential of your business through the power of cloud integration at Peron Cloud.
        </p>
        <a
          href="#"
          className="px-4 py-3 text-gray-50 transition-all transform bg-blue-400 rounded-[80px] hover:bg-blue-500 dark:hover:text-gray-100 dark:text-gray-100 "
        >
          Learn more
        </a>
      </div>
    </div>
  </div>
</section>
<section className="flex items-center  font-poppins dark:bg-gray-800 ">
  <div className="justify-center flex-1 max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
    <div className="flex flex-wrap items-center ">
      <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0">
        <div className="lg:max-w-md">
          <span className="text-xl font-semibold text-[#38B6FF]">
            About Us
          </span>
          <h2 className="mt-4 mb-6 text-2xl font-bold dark:text-gray-300">
            We are the large business expert in Europe and Asia
          </h2>
          <p className="mb-10  dark:text-gray-400 ">
          Peron Cloud is the expert in cloud integration, leading the way in Europe and Asia. Our commitment to excellence, guided by innovative principles, ensures businesses thrive in the digital era.
          </p>
        </div>
      </div>
      <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0">
        <div className="flex mb-4">
          <span className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-6 bg-[#38B6FF] rounded dark:bg-blue-500 dark:text-gray-100 text-gray-50">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="w-5 h-5 bi bi-file-earmark-code"
              viewBox="0 0 16 16"
            >
              <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
              <path d="M8.646 6.646a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 9 8.646 7.354a.5.5 0 0 1 0-.708zm-1.292 0a.5.5 0 0 0-.708 0l-2 2a.5.5 0 0 0 0 .708l2 2a.5.5 0 0 0 .708-.708L5.707 9l1.647-1.646a.5.5 0 0 0 0-.708z" />
            </svg>
          </span>
          <div>
            <h2 className="mb-4 text-xl font-bold leading-tight dark:text-gray-300 md:text-2xl">
              Design
            </h2>
            <p className="text-base leading-loose  dark:text-gray-400">
            Experience design excellence with Peron Cloud—where every element is crafted with precision and purpose, rooted in simplicity and functionality to elevate your cloud integration usability.

            </p>
          </div>
        </div>
        <div className="flex mb-4">
          <span className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-6 bg-[#38B6FF] rounded dark:bg-blue-500 dark:text-gray-100 text-gray-50">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="w-5 h-5 bi bi-file-text"
              viewBox="0 0 16 16"
            >
              <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
              <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
            </svg>
          </span>
          <div>
            <h2 className="mb-4 text-xl font-bold leading-tight dark:text-gray-300 md:text-2xl">
              Strategy
            </h2>
            <p className="text-base leading-loose  dark:text-gray-400">
            Partner with Peron Cloud for a strategic approach to cloud integration, tailoring solutions to your business objectives with a forward-thinking vision for sustainable and scalable digital infrastructure.

            </p>
          </div>
        </div>
        <div className="flex mb-4">
          <span className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-6 bg-[#38B6FF] rounded dark:bg-blue-500 dark:text-gray-100 text-gray-50">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="w-5 h-5 bi bi-bank2"
              viewBox="0 0 16 16"
            >
              <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z"></path>
            </svg>
          </span>
          <div>
            <h2 className="mb-4 text-xl font-bold leading-tight dark:text-gray-300 md:text-2xl">
              Develop
            </h2>
            <p className="text-base leading-loose  dark:text-gray-400">
            Trust Peron Cloud as your development partner, dedicated to bringing your cloud integration vision to life with robust, reliable solutions that drive growth and success in the digital landscape.



            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


    </div>
  )
}

export default Aboutus