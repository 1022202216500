import React, { useState } from 'react';

function DynamicSolutionsSection() {
  const [activeTab, setActiveTab] = useState('whats-new');
  const solutions = {
    'whats-new': [
      {
        id: '1',
        title: 'AI EVENT OF THE YEAR ',
        description: 'Register now for Google Cloud Next ‘24 to learn the latest in AI',
        icon: 'fas fa-calendar-alt',
        link: '/contact',
        image: 'https://images.hindustantimes.com/tech/img/2023/06/01/960x540/ai-generated-8015425_1280_1685615510615_1685634698923.jpg',
      },
      {
        id: '2',
        title: 'PRODUCT ANNOUNCEMENT',
        description: 'Learn how to customize and deploy Gemini in Vertex AI',
        icon: 'fas fa-cogs',
        link: '/contact',
        image: 'https://www.elegantthemes.com/blog/wp-content/uploads/2023/06/general-AI.jpg',

      },
      {
        id: '3',
        title: 'PRODUCT ANNOUNCEMENT',
        description: 'Deploy photorealistic image generation into production with Imagen 2 in Vertex AI',
        icon: 'fas fa-camera',
        link: '/contact',
        image: 'https://imageio.forbes.com/specials-images/imageserve/5f57db71653ff9e8e4d10083/AI--Machine-learning--Hands-of-robot-and-human-touching-on-big-data-network/960x0.jpg?format=jpg&width=1440',

      },
    ],
   'Hire A Team': [
      {
        id: '4',
        title: 'DEVELOPER OFFER',
        description: 'Earn a $1,000 credit for new Vertex AI Search and Conversation customers',
        icon: 'fas fa-money-bill',
        link: '/contact',
        image: 'https://d3lkc3n5th01x7.cloudfront.net/wp-content/uploads/2020/08/27040443/Full-stack-developer-1.png',

      },
      {
        id: '5',
        title: 'FREE AI TOOLS ',
        description: 'Explore the top 10 free offers for common AI use cases',
        icon: 'fas fa-toolbox',
        link: '/contact',
        image: 'https://www.digitalinfoways.com/wp-content/uploads/2022/02/Hire-Dedicated-Developers.png',

      },
    ],
    'Business leaders': [
      {
        id: '6',
        title: 'REPORT',
        description: 'Google Cloud named a leader in 2023 Gartner MQ for Cloud DBMS',
        icon: 'fas fa-file-alt',
        link: '/contact',
        image: 'https://www.simplilearn.com/ice9/free_resources_article_thumb/13_Famous_Business_Leaders_You_Must_Follow_in_2022.jpg',

      },
      {
        id: '7',
        title: 'ON-DEMAND AI EVENT',
        description: 'Watch key highlights from our Applied AI Summit',
        icon: 'fas fa-video',
        link: '/contact',
        image: 'https://www.cio.com/wp-content/uploads/2023/05/leadership-ts-100647856-orig.jpg?resize=1240%2C1240&quality=50&strip=all',

      },
      {
        id: '8',
        title: 'PRODUCT ANNOUNCEMENT',
        description: "Learn how Duet AI's code assistance can increase developer productivity",
        icon: 'fas fa-lightbulb',
        link: '/contact',
        image: 'https://www.ceo-review.com/wp-content/webp-express/webp-images/uploads/2020/11/business-people.jpg.webp',

      },
    ],
  };

  const handleTabClick = (tab) => setActiveTab(tab);
  return (
    <section className=" ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
        <div className="flex  flex-col md:flex-row justify-start mb-4 border-b-2 transition-transform duration-1000">
          {Object.keys(solutions).map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={`${
                activeTab === tab
                  ? ' text-lg border-b-2 border-blue-700 bg-[#38B6FF] transition-colors duration-300'
                  : ' text-lg transition-colors duration-1000'
              } px-4 py-2 mx-2 mb-2 md:mb-0`}
            >
              {tab === 'whats-new' ? "What's new in AI" : tab}
            </button>
          ))}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ">
          {(solutions[activeTab] || []).map((solution) => (
            <Card
              key={solution.id}
              title={solution.title}
              description={solution.description}
              icon={solution.icon}
              link={solution.link}
              image={solution.image}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

function Card({ title, description, icon, link, image }) {
  return (
    <div cla>
    <div className="bg-gray-200 transition-transform duration-700 hover:scale-105 ">
      <a
        href={link}
        className="rounded-md bg-white border border-gray-200 shadow-md hover:shadow-xl transition-transform duration-1000 ease-in-out grid"
      >
        <div className="flex items-center">
          <div className="p-4 w-1/2">
            <h3 className="text-base font-medium text-gray-900">{title}</h3>
            <p className="mt-2 text-sm text-gray-500">{description}</p>
          </div>
          <img
            src={image}
            alt={title}
            className="w-1/2 h-40 object-cover object-center rounded-tr-md rounded-br-md"
          />
        </div>
      </a>
    </div>
    </div>
  );
}

export default DynamicSolutionsSection;