import React, { useEffect, useRef, useState } from 'react';
import VideoPopupreplace from './VideoPopupreplace.js';
import Boxesofvideos from './boxesofvideos.js';

const VideoReplaceComponent = ({url}) => {
  const [currentVideo, setCurrentVideo] = useState('');

  const videoLinks = [
    {
      url: 'https://www.youtube.com/embed/yg2yHIKQ7oM',
      title: 'Build, tune, and deploy foundation models with Vertex AI',
      head: 'How-To'
    },
    {
      url: 'https://www.youtube.com/embed/XX2XpqklUrE',
      title: 'Build AI-powered apps in Vertex AI with LangChain',
      head: 'AI-DRIVEN APPLICATIONS'
    },
    {
      url: 'https://www.youtube.com/embed/HaL81be3elg',
      title: 'Use Duet AI to speed up development',
      head: 'TASK ASSISTANCE'
    },
    {
      url: 'https://www.youtube.com/embed/AbC123XYZ',
      title: 'Develop an app with Duet AI assistance',
      head: 'CODE ASSISTANCE'
    },
  ];

  const handleTextClick = (newVideo) => {
    setCurrentVideo(newVideo);
  };

  useEffect(() => {
    setCurrentVideo(videoLinks[0].url);
  }, []);
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleIframeClick = () => {
      openVideoPopup();
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener('click', handleIframeClick);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('click', handleIframeClick);
      }
    };
  }, []);

  const openVideoPopup = () => {
    setVideoPopupOpen(true);
  };

  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };


  return (
    <div>
    <div className="flex flex-col md:flex-row bg-black text-white max-lg:px-0 wpx-4 py-4">
        
      <div className="md:w-1/2 p-6 ">
        <h1 className="text-4xl font-bold mb-4 max-lg:ml-2 max-lg:text-3xl ml-16 relative">
          Build and scale generative AI apps
          <p>
            with <span className="text-yellow-400"> Vertex AI and Duet AI </span>
          </p>
        </h1>

        <div className="mt-6 flex justify-center">
  <div className='w-3/2'>
    {videoLinks.map((video, index) => (
      <p
        key={index}
        className={`cursor-pointer text- mb-10 mt-4 px-2  ${
          currentVideo === video.url ? 'bg-blue-700 text-white pt-2' : 'hover:bg-blue-400 pt-2 hover:text-white'
        } rounded-2xl`}
        onClick={() => handleTextClick(video.url)}
      >
        <span className="mr-4 ml-2 px-2">{video.head}</span>
        <p className="text-lg ml-2 py-2">{video.title}</p>
      </p>
    ))}
  </div>
</div>
</div>

      <div className="md:w-1/2 p-6">
        
        <p className="text-xl mb-4">
          Use <a className="text-blue-400 underline" href="https://cloud.google.com/vertex-ai?hl=en">Vertex AI </a> to deploy and manage AI applications and{' '}
          <span className="text-blue-400 underline cursor-pointer">Duet AI</span> for
          <p> task and code assistance. </p>
        </p>
        <button className="bg-white rounded-sm text-black px-6 py-2 mt-6">Get started for free</button>
        
        {currentVideo && (
        <div className="aspect-w-16 aspect-h-9 mt-6 h-4/6 " style={{ position: 'relative' }}>
          <div
            className="overlay-div"
            onClick={openVideoPopup}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1, 
            }}
          ></div>
          <iframe
            className="w-full h-full"
            src={currentVideo}
            title="Develop an app with Duet AI assistance"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
    

    {isVideoPopupOpen && (
      <VideoPopupreplace
        onClose={closeVideoPopup}
        videoUrl={currentVideo}  
      />
    )}
    
  </div>
  <Boxesofvideos/>

  </div>
  );
};

export default VideoReplaceComponent;
