import React from 'react';

const VideoPopup = ({ onClose }) => {
  return (
    <div className="fixed z-10 inset-0 bg-black bg-opacity-50 flex items-center justify-center shadow-2xl">
      <div className="relative w-3/4 h-3/4 max-w-3xl max-h-screen overflow-hidden bg-white rounded-lg shadow-xl">
        {/* Your video player iframe */}
        <iframe
          width="853" height="480" src="https://www.youtube.com/embed/tCHYMtccduY" title="Google Cloud: The new way to cloud" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen
          className="w-full mt-16   rounded-lg overflow-hidden"
        ></iframe>
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-black  text-4xl cursor-pointer"
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default VideoPopup;
