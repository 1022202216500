import React from 'react'
import { FaDribbble, FaFacebook, FaGithub, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div><>
 
    <footer className="">
      <div className="mx-auto  px-4 pt-16 sm:px-6 lg:px-8">
        <div className="mx-auto  flex justify-between max-lg:flex-col">
          <Link className='w-72 max-lg:mb-10' to='/'>
        <img src='/preon23.png' />
        </Link>
   
          <form className=" right-0">
            <div className="relative max-w-lg">
              <label className="sr-only" htmlFor="email">
                {" "}
                Email{" "}
              </label>
              <input
                className="w-full rounded-full border-gray-200 bg-gray-100 p-4 pe-32 text-sm font-medium"
                id="email"
                type="email"
                placeholder="john@doe.com"
              />
              <Link>
              <button className="absolute end-1 top-1/2 -translate-y-1/2 rounded-full bg-[#38B6FF] px-5 py-3 text-sm font-medium text-white transition hover:bg-blue-700">
              Subscribe
              </button>
              </Link>
            </div>
          </form>
        </div>
        <div className="mt-16 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-32 max-lg:text-left">
          <div className="mx-auto max-w-sm lg:max-w-none max-lg:text-left">
            <p className="mt-4 text-center max-lg:text-left  lg:text-left lg:text-lg">
            Stay informed with the latest trends and innovations in cloud computing. Subscribe to our newsletter for regular updates
            Ready to embark on your cloud journey? Reach out to us for personalized solutions tailored to your business needs
            Follow us on social media for the latest updates and insights
            </p>
            <div className="mt-6 flex justify-center gap-4 lg:justify-start">
              <a
                className=""
                href=""
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> Facebook </span>
       <FaFacebook size='30'/>
              </a>

              <a
                href=""
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> Instagram </span>
              <FaInstagram size='30'/>
              </a>
              <a
                href=""
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> Instagram </span>
              <FaLinkedinIn size='30'/>
              </a>
              <a
                href=""
                target="_blank"
                rel="noreferrer"
              >
            <FaTwitter size='30'/>
              </a>
              <a
                href=""
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> GitHub </span>
<FaGithub size='30'/>
              </a>
              
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 text-center  lg:grid-cols-3 max-lg:text-left">
            <div>
              <strong className="font-medium "> Services </strong>
              <ul className="mt-6 space-y-1">
                <li>
                  <a
                    className=" transition "
                    href="/"
                  >
                    {" "}
                    Marketing{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                  >
                    Graphic Design
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                  >
                    App Development
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                  >
                    Web Development
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <strong className="font-medium "> About </strong>
              <ul className="mt-6 space-y-1">
                <li>
                  <a
                    href="/"
                  >
                    {" "}
                    About{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                  >
                    {" "}
                    Careers{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                  >
                    {" "}
                    History{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                  >
                    {" "}
                    Our Team{" "}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <strong className="font-medium "> Support </strong>
              <ul className="mt-6 space-y-1">
                <li>
                  <a
                    href="/"
                  >
                    {" "}
                    FAQs{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                  >
                    {" "}
                    Contact{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                  >
                    {" "}
                    Live Chat{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-gray-100 pt-8">
          <p className="text-center text-xs/relaxed ">
            © Company 2022. All rights reserved.
            <br />
            Created with
            <a
              href=""
              className="text-[#38B6FF] underline transition hover:text-gray-700/75"
            >
              Laravel
            </a>
            and
            <a
              href=""
              className="text-[#38B6FF] underline transition hover:text-gray-700/75"
            >
              Laravel Livewire
            </a>
            .
          </p>
        </div>
      </div>
    </footer>
  </>
  </div>
  )
}

export default Footer