// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import CaseStudyCarousel from './Components/ProductSection/CaseStudyCarousel';
// import CaseStudyDetails from './Components/ProductSection/CaseStudyDetails';
import Navbar from './Components/App/Navbar/Navbar';
import Footer from './Components/App/Footer/Footer';
import HeroSection from './Components/App/HeroSection/Hero';
import DynamicSolutionsSection from './Components/App/HeroSection/Googlesection';
import ProductsDropdown from './Components/App/ProductDescription/ProductDescription';
import VideoComponent from './Components/App/videoreplace/videoreplace';
import Boxses from './Components/App/Boxs/Boxses';
import Textsection from './Components/App/Boxs/textsection';
import Reviews from './Components/App/Reviews/Reviews';
import LogoSection from './Components/App/logoSection/logosSection';
import CloudBox from './Components/App/CloudBox/CloudBox';
import TextCloud from './Components/App/Reviews/textcloud';
import ServicesSection from './Components/App/ServicesSection/ServicesSection';
import Aboutus from './Components/App/AboutUs/Aboutus';
import ContactSection from './Components/ContactSection/ContactSection';
import CaseStudyCarousel, { caseStudies } from './Components/ProductSection/ProductSection';
import CaseStudyDetails from './Components/ProductSection/BoxDetailsPage';
import Blog from './Components/App/Blog/Blog';
import AdvanceBoxse from './Components/App/AdvanceServices/AdvanceBoxse';
import Reviewsuser from './Components/App/ReviewUser/Reviewuser';

function App() {
  return (
    <Router>
      <div className="container mx-auto font-sans bg-black text-white">
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HeroSection />
                <DynamicSolutionsSection />
                <ProductsDropdown />
                {/* <VideoComponent /> */}
                {/* <Boxses /> */}
                <Textsection />
                <Reviews />
                <AdvanceBoxse/>
                <LogoSection />
                <CloudBox />
                <CaseStudyCarousel/>
                <Reviewsuser/>
                <TextCloud />
              </>
            }
          />
        <Route path="/box-details/:index" element={<CaseStudyDetails caseStudies={caseStudies} />} />

          <Route path="/services" element={<ServicesSection />} />
          <Route path="/about" element={<Aboutus />} />
          <Route path="/casestudies" element={<CaseStudyCarousel />} />
          <Route path="/contact" element={<ContactSection />} />

          <Route path='/blog' element={<Blog />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
