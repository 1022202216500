import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';

const CaseStudyDetails = ({ caseStudies }) => {
  const { index } = useParams();
  const caseStudy = caseStudies && caseStudies[parseInt(index)];

  if (!caseStudy) {
    return <div>Case study not found</div>;
  }

  return (
    <div className="flex flex-col items-center max-lg:mx-4  mx-10 my-20">
      <h2 className="text-center mb-10 font-bold text-2xl">{caseStudy.title}</h2>

      <div className="shadow-lg rounded-lg bg-gray-100 p-4 mb-8">
        <img src={caseStudy.image} alt={caseStudy.title} className="max-w-full h-auto" />
      </div>

      <div className="text-center  justify-center w-1/2 mb-8">
        <div className='flex items-center justify-center my-10'>
      {caseStudy.languages.map((language, i) => (
            <div key={i} className="mr-2 ">
              <FontAwesomeIcon icon={caseStudy.languageIcons[i]} className="text-3xl text-indigo-500" title={language} />
            </div>
          ))}
          </div>
        <div className="flex justify-center mb-4">
          
      <p className=" ">{caseStudy.description}</p>
 
        </div>
      </div>

      <div className="w-full grid grid-cols-1 gap-4 mb-8">

        
        <div className="flex max-lg:flex-col items-center justify-center shadow-lg max-lg:px-1 px-10 rounded-lg py-4 ">
          <div className='flex  flex-col w-1/2 max-lg:w-auto max-lg:px-2 px-10 '>
          <h3 className="text-bold text-3xl font-semibold">Problems</h3>
            {caseStudy.problems.issues.map((issue, i) => (
              <p className='mb-2 text-lg mt-10' key={i}>{issue}</p>
            ))}
          </div>
          <div className=''>
          <img src={caseStudy.problems.image} alt="Problem" className="mt-2" />
          </div>
        </div>
        <div className="flex flex-col items-center w-3/2">
          <h3 className="text-lg font-semibold">Used Stack</h3>
          <div className='flex max-sm:flex-col mx-10 my-10 px-10 '>
          {caseStudy.languages.map((language, i) => (
            <div key={i} className="mr-2 max-lg:px-2 px-10 ">
              <FontAwesomeIcon icon={caseStudy.languageIcons[i]} className="text-6xl  text-indigo-500" title={language} />
            </div>
          ))}
          </div>
          <div className='flex max-lg:flex-col'>
            {caseStudy.solutionStack.technologies.map((technology, i) => (
              <img key={i} src={caseStudy.solutionStack.icon} alt={technology} className="w-auto h-auto mx-auto mb-2" />
              
            ))}
            
          </div>
        </div>
      </div>
        {caseStudy.solutions.map((solution, i) => (
          <div key={i} className="flex max-lg:flex-col items-center justify-center shadow-lg max-lg:px-2 px-10 rounded-lg py-4">
            < div className='w-1/2 max-lg:w-auto'>
            <img src={solution.image} alt={solution.title} className=" h-auto mb-2" />
            </div>
<div className='flex-col w-1/2 max-lg:w-auto'>
            <h3 className=" font-bold text-xl">{solution.title}</h3>
          
          
            <p className='text-lg'>{solution.description}</p>
            </div>
          </div>

        ))}
   

      <div className="w-full grid grid-cols-3 max-lg:grid-cols-1 gap-4">
        {caseStudy.extraImages.map((image, i) => (
          <img key={i} src={image} alt={`Extra Image ${i + 1}`} className="max-w-full h-auto" />
        ))}
      </div>
    </div>
  );
};

export default CaseStudyDetails;
