// LogoSection.jsx

import React, { useState, useEffect } from 'react';

const logos = [
  'https://lh3.googleusercontent.com/WQUsu5egTEcuX_i9vIfb_ExuIn82AiCOGEUrQXzfts6_MqUYmb5IF0U48Sxqiug-u-6GgmmPx1w',
  'https://lh3.googleusercontent.com/WAE3EtGkgDCFY_ExE0UcheV7SOQEmILfAXacrTMXgKK84QF9t097udvMiZX0WvLrokCeZVDbiQg',
  'https://lh3.googleusercontent.com/xOrxeCJLbYzmhf-KB5oQ12ubnaBtOwjIzubeXh5oB9b1yYHPzcjVQeG7jQhGuchdJ-Wbr3LP845c',
  'https://lh3.googleusercontent.com/mIlzwngwk9PtCl7OJEruOk_cpIZhovKDR1QFNANQWq815ccqN6SRYq5HNuB8aguuZ83-HLOPZnI',
  'https://lh3.googleusercontent.com/SCRllnrrrAuTTuKhNQYj__8h8QOrQGFiLJhf-aBoPJY4egs8fWwSHF9Z2Cs3RSNQHP6Ns5LpF5PF',
  'https://lh3.googleusercontent.com/McXvXJHmGEzr4O-4N-OFwlOiZNBdpcL50X2Rayh1nKLyyPWwZ4oWoa2p7t13x-xMZbssjhl8BVI',
  'https://lh3.googleusercontent.com/XKiD2yMbbNLj90I4sXcqHtjC7iKcetDpm_hhCX6W93gH2HeZtALLGp6KSPNV5xm973eE3lURrlx0EQ',
  'https://lh3.googleusercontent.com/k74AA94vt7-3NTHHVKMJeUjagh5Wr-cAPoMsSBEDLbeLIaqd8rluiR7s0cZQycQ_oHrNylgk3pOs',
  'https://lh3.googleusercontent.com/h9qgLBntJ2SDFfu5TuGbN_6bMxXA3tK0r1N3wd9OS84OORRofTgt519WY9A8g8wGpGsWTvCTH9aR'
];

const LogoSection = () => {
  const [currentLogos, setCurrentLogos] = useState(logos.slice(0, 4));
  const [logoIndex, setLogoIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLogoIndex((prevIndex) => (prevIndex + 4) % logos.length);
      setCurrentLogos(logos.slice(logoIndex, logoIndex + 4));
    }, 10000); 

    return () => clearInterval(interval);
  }, [logoIndex]);

  const handleSeeMoreClick = () => {
    const newIndex = (logoIndex + 4) % logos.length;
    setLogoIndex(newIndex);
    setCurrentLogos(logos.slice(newIndex, newIndex + 4));
  };
  

  return (
<div className="flex flex-col justify-center items-center max-md:mx-6  relative border-2 my-10 mx-auto max-w-screen-lg rounded-xl">
  <div className="flex flex-wrap justify-center">
    {currentLogos.map((src, index) => (
      <div
        key={index}
        className="transition-transform duration-300 transform hover:scale-110 mx-2 animate-slide-up"
      >
        <img
          src={src}
          alt={`logo-${index}`}
          className="h-24 w-24 my-4 mx-16 object-contain"
        />
      </div>
    ))}
  </div>
  <p
    onClick={handleSeeMoreClick}
    className="mt-4 px-4 py-2 rounded-md font-sans cursor-pointer focus:outline-none focus:ring focus:border-blue-300"
  >
    See More Companies
  </p>
</div>
  )}
export default LogoSection;
