import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { FaArrowRight, FaBars, FaChevronDown, FaChevronUp, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const desktopDropdownRef = useRef(null);
  const mobileDropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeDropdownOnOutsideClick = (event, dropdownRef) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    const closeDesktopDropdown = (event) => {
      closeDropdownOnOutsideClick(event, desktopDropdownRef);
    };

    const closeMobileDropdown = (event) => {
      closeDropdownOnOutsideClick(event, mobileDropdownRef);
    };

    document.addEventListener("click", closeDesktopDropdown);
    document.addEventListener("click", closeMobileDropdown);

    return () => {
      document.removeEventListener("click", closeDesktopDropdown);
      document.removeEventListener("click", closeMobileDropdown);
    };
  }, []);

  return (
    <header className="flex flex-wrap sm:justify-start sm:flex-nowrap w-full  text-sm py-4 dark:bg-gray-800">
      <nav
        className="max-w-[85rem] w-full mx-auto px-4 flex flex-wrap basis-full items-center justify-between"
        aria-label="Global"
      >
        <Link
          to="/"
          className="sm:order-1 flex-none text-xl font-semibold dark:text-white"
        >
          <img
            src="/preon23.png" // Update with your logo path
            alt="Your Brand Name"
            className="h-8 w-auto mr-2 max-md:h-4"
          />
        </Link>
        <div className="sm:order-3 flex items-center gap-x-2">
  
          <Link
                to="/contact"
                className="text-center flex  bg-[#38B6FF] px-4 rounded-md  text-white  py-3"
              >
               <span className="text-lg max-lg:text-xs">Schedule a Call</span>
  <FaArrowRight className=" hover:ml-6 ml-2 mt-2 max-lg:text-xs max-lg:mt-0 transition-all duration-1000 transform " /> {/* Adjust margin-left as needed */}

              </Link>
              <button
  type="button"
  className="sm:hidden hs-collapse-toggle px-4 py-3 inline-flex justify-center items-center gap-x-2 rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-gray-700 dark:text-white dark:hover:bg-white/10 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
  onClick={toggleMobileMenu}
>
  
{isMobileMenuOpen ? <FaTimes className="text-2xl" /> : <FaBars />}
   
</button>
        </div>
        <div
          id="navbar-alignment"
          className={isMobileMenuOpen ? "hs-collapse overflow-hidden transition-all duration-300 basis-full grow sm:grow-0 sm:basis-auto sm:block sm:order-2" : "hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:grow-0 sm:basis-auto sm:block sm:order-2"}
          ref={mobileDropdownRef}
        >
          <div className="flex flex-col gap-5 mt-5 sm:flex-row sm:items-center sm:mt-0 sm:ps-5">
            <Link
              to="/casestudies"
              className="font-medium text-xl  dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              aria-current="page"
            >
              Case Study
            </Link>
            <Link
              to="/services"
              className="font-medium  text-xl hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            >
             Services
            </Link>
            
            <div className="group " ref={desktopDropdownRef}>
              <button
                onClick={toggleDropdown}
                className="font-medium  text-xl flex hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              >
                More <p>{isDropdownOpen ? <FaChevronUp className="mt-1 ml-1" /> : <FaChevronDown className="mt-1 ml-1" />}</p>
              </button>
              {isDropdownOpen && (
                <div className="absolute mt-2 z-50 space-y-2 bg-white bg-transparent text-gray-800 border rounded-lg p-10">
                  <Link to="/blog" className="block py-2 border-b">
                    Blog
                  </Link>
                  <Link to="/about" className="block py-2 border-b">
                    About
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
