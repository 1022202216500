import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHtml5,
  faJs,
  faPython,
  faNodeJs,
  faReact,
  faTypo3,
} from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom"; // Importing useNavigate
import CaseStudyDetails from "./BoxDetailsPage";
import Slider from "react-slick"; // Import Slider component from react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
export const caseStudies = [
  {
    title: "Intellirent - Rental Data Manager",
    tag: "Product Managment, Product Developement",
    image:
      "/web1.png",
    description:
      "Intellirent is an online platform designed to help property managers and landlords make informed rental decisions..",
    languages: ["HTML/CSS", "JavaScript", "Python", "Node.js"],
    languageIcons: [faHtml5, faJs, faPython, faNodeJs],
    solutionStack: {
      languageIcons: [faHtml5, faJs, faPython, faNodeJs],

      technologies: ["React", "Node.js", "MongoDB"],
      icon: "https://i.pinimg.com/originals/6b/af/d7/6bafd77fd2633aa0b469df9cef31c6b8.gif",
    },
    solutions: [
      {
        title: "Solution 1",
        description:
          "The development team has devised a multifaceted approach to address the applications challenges, focusing on scalability and UI/UX improvements. For scalability, they intend to implement optimizations like caching mechanisms, database indexing, and horizontal scaling techniques to bolster the applications ability to handle increased traffic and growing datasets without compromising performance. Simultaneously, they plan to conduct thorough user research to pinpoint pain points and areas for enhancement in the UI/UX. Based on these insights, they will redesign interface elements, streamline navigation paths, and optimize interactions to create a more intuitive user experience. Importantly, these scalability and UI/UX solutions will be seamlessly integrated to ensure a cohesive user journey. By prioritizing both technical robustness and user satisfaction, the team aims to elevate the applications overall performance and usability..",
        image:
          "https://images-platform.99static.com//BoaH5IzY9MbZTChZ6gn1D7nxjGs=/0x0:1336x1336/fit-in/590x590/99designs-contests-attachments/84/84603/attachment_84603197",
      },
      // Add more solutions as needed
    ],
    problems: {
      issues: [
        "Scalability issues:/n The identified problem with the current web application includes scalability issues. This suggests that the application may struggle to handle increasing loads or accommodate growing user bases effectively, potentially leading to performance degradation or system failures.",
        "UI/UX improvements needed: Another issue identified is the need for UI/UX improvements. This implies that the user interface and experience may not meet users  expectations or requirements, which could result in reduced user satisfaction and engagement.      ",
      ],
      image:
        "https://images-platform.99static.com//BoaH5IzY9MbZTChZ6gn1D7nxjGs=/0x0:1336x1336/fit-in/590x590/99designs-contests-attachments/84/84603/attachment_84603197",
    },
    extraImages: [
      "https://www.webcitz.com/blog/wp-content/uploads/2022/08/DropSuite-1-1024x610.png",
      "https://www.webcitz.com/blog/wp-content/uploads/2022/08/DropSuite-1-1024x610.png",
      "https://www.webcitz.com/blog/wp-content/uploads/2022/08/DropSuite-1-1024x610.png",
    ],
  },

  {
    title: "KUDO - Languages Translator",
    tag: "Product Managment, Product Developement",

    image:
      "/web2.png",
    description:
      "KUDO provides an interpretation service that matches its clients’ requirements for meetings, webinars, and events on demand.",
    languages: ["JavaScript", "TypeScript", "React", "Node.js"],
    languageIcons: [faJs, faTypo3, faReact, faNodeJs],
    solutionStack: {
      languageIcons: [faHtml5, faJs, faPython, faNodeJs],

      technologies: ["React", "Node.js", "MongoDB"],
      icon: "https://i.pinimg.com/originals/6b/af/d7/6bafd77fd2633aa0b469df9cef31c6b8.gif",
    },
    solutions: [
      {
        title: "Solution 1",
        description:
          "The development team has devised a multifaceted approach to address the applications challenges, focusing on scalability and UI/UX improvements. For scalability, they intend to implement optimizations like caching mechanisms, database indexing, and horizontal scaling techniques to bolster the applications ability to handle increased traffic and growing datasets without compromising performance. Simultaneously, they plan to conduct thorough user research to pinpoint pain points and areas for enhancement in the UI/UX. Based on these insights, they will redesign interface elements, streamline navigation paths, and optimize interactions to create a more intuitive user experience. Importantly, these scalability and UI/UX solutions will be seamlessly integrated to ensure a cohesive user journey. By prioritizing both technical robustness and user satisfaction, the team aims to elevate the applications overall performance and usability..",
        image:
          "https://images-platform.99static.com//BoaH5IzY9MbZTChZ6gn1D7nxjGs=/0x0:1336x1336/fit-in/590x590/99designs-contests-attachments/84/84603/attachment_84603197",
      },
      // Add more solutions as needed
    ],
    problems: {
      issues: [
        "Scalability issues:/n The identified problem with the current web application includes scalability issues. This suggests that the application may struggle to handle increasing loads or accommodate growing user bases effectively, potentially leading to performance degradation or system failures.",
        "UI/UX improvements needed: Another issue identified is the need for UI/UX improvements. This implies that the user interface and experience may not meet users  expectations or requirements, which could result in reduced user satisfaction and engagement.      ",
      ],
      image:
        "https://images-platform.99static.com//BoaH5IzY9MbZTChZ6gn1D7nxjGs=/0x0:1336x1336/fit-in/590x590/99designs-contests-attachments/84/84603/attachment_84603197",
    },
    extraImages: [
      "https://www.webcitz.com/blog/wp-content/uploads/2022/08/DropSuite-1-1024x610.png",
      "https://www.webcitz.com/blog/wp-content/uploads/2022/08/DropSuite-1-1024x610.png",
      "https://www.webcitz.com/blog/wp-content/uploads/2022/08/DropSuite-1-1024x610.png",
    ],
  },
  {
    title: "Recurate - Reselling Channel",
    tag: "Product Managment, Product Developement",

    image:"/web3.png",
    description:
      "Recurate is a full-service re-commerce partner that enables a used product P2P market directly on eCommerce stores.",
    languages: ["Illustrator", "JavaScript", "Python", "React"],
    languageIcons: [faTypo3, faJs, faPython, faReact],
    solutionStack: {
      languageIcons: [faHtml5, faJs, faPython, faNodeJs],

      technologies: ["React", "Node.js", "MongoDB"],
      icon: "https://i.pinimg.com/originals/6b/af/d7/6bafd77fd2633aa0b469df9cef31c6b8.gif",
    },
    solutions: [
      {
        title: "Solution 1",
        description:
          "The development team has devised a multifaceted approach to address the applications challenges, focusing on scalability and UI/UX improvements. For scalability, they intend to implement optimizations like caching mechanisms, database indexing, and horizontal scaling techniques to bolster the applications ability to handle increased traffic and growing datasets without compromising performance. Simultaneously, they plan to conduct thorough user research to pinpoint pain points and areas for enhancement in the UI/UX. Based on these insights, they will redesign interface elements, streamline navigation paths, and optimize interactions to create a more intuitive user experience. Importantly, these scalability and UI/UX solutions will be seamlessly integrated to ensure a cohesive user journey. By prioritizing both technical robustness and user satisfaction, the team aims to elevate the applications overall performance and usability..",
        image:
          "https://images-platform.99static.com//BoaH5IzY9MbZTChZ6gn1D7nxjGs=/0x0:1336x1336/fit-in/590x590/99designs-contests-attachments/84/84603/attachment_84603197",
      },
      // Add more solutions as needed
    ],
    problems: {
      issues: [
        "Scalability issues:/n The identified problem with the current web application includes scalability issues. This suggests that the application may struggle to handle increasing loads or accommodate growing user bases effectively, potentially leading to performance degradation or system failures.",
        "UI/UX improvements needed: Another issue identified is the need for UI/UX improvements. This implies that the user interface and experience may not meet users  expectations or requirements, which could result in reduced user satisfaction and engagement.      ",
      ],
      image:
        "https://images-platform.99static.com//BoaH5IzY9MbZTChZ6gn1D7nxjGs=/0x0:1336x1336/fit-in/590x590/99designs-contests-attachments/84/84603/attachment_84603197",
    },
    extraImages: [
      "https://www.webcitz.com/blog/wp-content/uploads/2022/08/DropSuite-1-1024x610.png",
      "https://www.webcitz.com/blog/wp-content/uploads/2022/08/DropSuite-1-1024x610.png",
      "https://www.webcitz.com/blog/wp-content/uploads/2022/08/DropSuite-1-1024x610.png",
    ],
  },
];

const CaseStudyCarousel = () => {
  const navigate = useNavigate(); // Using useNavigate hook
  const [activeIndex, setActiveIndex] = useState(0);
  const [showDetails, setShowDetails] = useState(false);

  const handleViewDetails = (index) => {
    setActiveIndex(index);
    setShowDetails(true);
  };

  const caseStudy = caseStudies[activeIndex];

  const navigateToDetails = (index) => {
    handleViewDetails(index);
    navigate(`/box-details/${index}`); // Using navigate function
  };

  const CustomPrevArrow = (props) => (
    <button
      {...props}
      className="absolute max-lg:hidden top-1/2 left-0 transform -translate-y-1/2 w-10 h-10 bg-gray-800 text-white rounded-full flex items-center justify-center z-10 focus:outline-none"
    >
      <FaArrowLeft className="ml-3" />
    </button>
  );

  const CustomNextArrow = (props) => (
    <button
      {...props}
      className="absolute top-1/2 max-lg:hidden right-0 transform -translate-y-1/2 w-10 h-10 bg-gray-800 text-white rounded-full flex items-center justify-center z-10 focus:outline-none"
    >
      <FaArrowRight className="ml-3" />
    </button>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 9000,
    beforeChange: (current, next) => setActiveIndex(next),
    prevArrow: <CustomPrevArrow />, // Custom previous arrow
    nextArrow: <CustomNextArrow />, // Custom next arrow
  };

  return (
    <section className="  relative ">
      <div className="w-auto px-4 py-8 mx-auto lg:px-6 sm:py-16 lg:py-24 relative">
        {" "}
        {/* Added relative positioning */}
        <div className="text-center ">
          <h2 className="text-3xl font-extrabold leading-tight text-white tracking-tight  sm:text-4xl dark:text-white">
            Case Studies
          </h2>
          <p className="mt-4 text-base font-normal text-white  sm:text-xl dark:text-gray-400">
            Discover how our IT solutions drive ROI and traffic for our clients
            worldwide.
          </p>
        </div>
        <Slider {...settings} className=" ">
          {caseStudies.map((study, index) => (
            <div
              key={study.title}
              className=" px-10 py-10 mt-10 relative   bg-blue-  "
              style={{ zIndex: caseStudies.length - index }} // Ensure proper layering of bubbles
            >
              <div className="flex max-lg:flex-col ">
                <div className="col-span-1 flex flex-col px-10 max-lg:px-2 realtive mt-32 z-50">
                  <div>
                    <h3 className="text-5xl font-bold mb-6 leading-tight text-white dark:text-white mt-2">
                      {study.title}
                    </h3>
                    {study.tag &&
                      study.tag.split(",").map((tag) => (
                        <span
                          key={tag.trim()}
                          className="bg-gray-200 ml-3 max-lg:flex max-lg:mt-3 max-lg:flex-col   text-gray-900 text-xs font-medium px-6 py-4 dark:bg-gray-700 dark:text-gray-300"
                        >
                          {tag.trim()}
                        </span>
                      ))}
                    <p className="text-2xl font-normal mt-6 text-white  dark:text-gray-400 ">
                      {study.description}
                    </p>
                  </div>
                  <div className="flex mt-2">
                    {study.languages.map((language, i) => (
                      <div key={i} className="mr-2">
                        <FontAwesomeIcon
                          icon={study.languageIcons[i]}
                          className="text-xl text-[#38B6FF] "
                          title={language}
                        />
                      </div>
                    ))}
                  </div>
                  <button
                    className="text-white  w-60 bg-[#38B6FF] justify-center hover:bg-primary-800 inline-flex items-center focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    onClick={() => navigateToDetails(index)}
                  >
                    View case study
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 ml-2 -mr-1 hover:ml-6  transition-all duration-300 transform hover:scale-105"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <img
                  className="mx-auto z-10"
                  src={study.image}
                  alt={study.title}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {showDetails && <CaseStudyDetails caseStudy={caseStudy} />}
    </section>
  );
};

export default CaseStudyCarousel;
