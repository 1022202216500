import React, { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const data = [
  { id: 1, name: 'Software development Team', imageUrl: 'https://images.unsplash.com/photo-1522071820081-009f0129c71c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', text1: 'Hire A Software developer', text2: 'retail companies trust Peron Cloud', logoUrl: 'https://devsinc.com/wp-content/uploads/2023/12/Software-Dev.svg', logoText: 'Our developers craft custom software solutions by leveraging cutting-edge technologies, like blockchain and AI, to streamline workflow and reliability.' },
  { id: 2, name: 'Mobile Development Team', imageUrl: 'https://img.freepik.com/free-photo/graphic-designers-discussing-laptop_1170-1865.jpg?size=626&ext=jpg&ga=GA1.1.670115861.1704553191&semt=ais', text1: 'Hire A Mobile  developer', text2: 'Top consumer goods companies trust Peron Cloud', logoUrl: 'https://devsinc.com/wp-content/uploads/2023/12/Mobile-Dev.svg', logoText: 'Our mobile app developers optimize cost, speed, performance, and maintenance by choosing native, hybrid, and cross-platform stacks for your business outlook.' },
  { id: 3, name: 'Web Development Team', imageUrl: 'https://media.istockphoto.com/id/606672336/photo/designer-drawing-website-development.jpg?s=612x612&w=0&k=20&c=mTSEYHpthOsiMTeQdw8MdRnBht2UOS338uJxST5cocs=', text1: 'Hire A Web Developer', text2: 'Top banking and insurance companies choose Peron Cloud', logoUrl: 'https://devsinc.com/wp-content/uploads/2023/12/Web-Dev.svg', logoText:'From front-end to full-stack, web developers at Devsinc build and maintain sites that are efficient and structured to perform according to your business requirements.' },
  { id: 4, name: 'Quality Assurance Team', imageUrl: 'https://img.freepik.com/premium-photo/business-technology-connection-communication-people-concept-smiling-business-team-with-tablet-pc-computer-virtual-contacts-projection-having-discussion-office_380164-86011.jpg?size=626&ext=jpg&ga=GA1.1.670115861.1704553191&semt=ais', text1: 'Hire A Quality Assurance Team ', text2: 'Quality and Assurance companies trust Peron Cloud', logoUrl: 'https://devsinc.com/wp-content/uploads/2023/12/SQA.svg', logoText: 'The quality assurance team works on test plans, executing test cases, identifying and documenting bugs, and more to deliver high-quality, reliable software.' },
  { id: 5, name: 'Design Team', imageUrl: 'https://img.freepik.com/premium-photo/top-view-business-group-multiethnic-busy-people-meeting-with-other-modern-office-with-laptop-computer-smartphone-tablet-coffee-table_1091882-8025.jpg?size=626&ext=jpg&ga=GA1.1.670115861.1704553191&semt=ais', text1: 'Hire A Designer', text2: 'Design and Develop companies trust on Peron Cloud  ', logoUrl: 'https://devsinc.com/wp-content/uploads/2023/12/UIUX.svg', logoText: 'With tools like Adobe Creative Suite, our design experts transform creative concepts into pixel-perfect visuals to harmonize aesthetics and functionalities simultaneously.' },
  { id: 6, name: 'Digital Markting Team', imageUrl: 'https://media.istockphoto.com/id/1088363648/photo/marketing-leads-and-sales-concept.jpg?s=612x612&w=0&k=20&c=52DWv2lz-IWUdu6_skh6gScXe0YpGSV2Kn3EodJyoTs=', text1: 'Hire  Digital Markting Team', text2: 'For  Markting  trust peron Cloud  ', logoUrl: 'https://devsinc.com/wp-content/uploads/2023/12/Digital-Marketin.svg', logoText: 'The digital marketing team captivates the audience’s attention while utilizing data-driven campaigns to give your business an elevated boost in its growth and profitability.' },
  { id: 7, name: 'Cyber Security Team', imageUrl: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgiwRx0yzU-skcMwUTVZn3oSEYUsitmVGlTP09Skd7fk9Ap3fL7VvBu6_6CpwRmgjyEHsasqrqyjgXCxAd4FfUqXhg-LBANTJbDf1hk86XjTL3QtMWrAdrSAoESbxMfym4fHRS7M4TLPGLF0_HQtip14c6c649_xakgw4FCsu-2LpYWdgq7wetp_0XA/s728-rw-e30/cybersecurity-teams.jpg', text1: 'Hire  Cyber Security Team', logoUrl: '/shield.png', logoText: 'We mitigate risks and allocate space for a secure upward trajectory through our cybersecurity consulting services.' },

];
// { id: 8, name: 'Marketing Team', imageUrl: 'https://lh3.googleusercontent.com/Jlo-KLZDt-amSE1n1xEQmpLzCeqUYZ3WaM6YKLqZRw5CzYmQVDXTp7SvTLvbryapIpuzhNjxnLUshw=rw-lo', text1: '7 / top 10', text2: 'manufacturing companies trust Google Cloud', logoUrl: 'https://lh3.googleusercontent.com/7r4q7e2JrmrRNktedkDwYzF9EyUraBtX6xYCBpsEtlq_Q_yOU061A3pBfISPvsDFfwHHuUZQmerO=rw-lo', logoText: 'The French automaker transforms its supply chain with Google AI solutions, resulting in more accurate quality controls and reduced time to market. Discover what you can do with our manufacturing solutions.' },
// { id: 9, name: 'Apllication Security Services', imageUrl: 'https://lh3.googleusercontent.com/GkGb5YI-CIXrOePcAZIpJZINEm379YGiWhhQ6Ei1kJ0j54vGeFiepmifX-MQp1u0ADZPNoQT43k=rw-lo', text1: '', text2: 'top supply chain and logistics companies choose Google Cloud', logoUrl: 'https://lh3.googleusercontent.com/xGJQ-HwIDNIESh2O3xnU6BLgHPPTShWkZIPT5KLnZcMeVeyswbbw_1-u3vQnGHvgF8G_PGBT0LOB=rw-lo', logoText: 'LJ.B. Hunt enhances shipping efficiency and makes informed decisions on available capacity with data cloud products. Discover what you can do with our supply chain and logistics solutions.  ' },
// { id: 10, name: 'System Integration', imageUrl: 'https://lh3.googleusercontent.com/_gEN84zMz9igSeQ1DCFpLb1tx2jmVVzvlAvIW9IfE_DyRK1j73LC31zFdfPtMFlnr7dGPzDd2mQueQ=rw-lo', text1: '', text2: 'Top government agencies choose Google Cloud  ', logoUrl: 'https://lh3.googleusercontent.com/R8J4qg3pQ4grKs8KhD3_60j-bVsIe1Rf2WgCqbnhezVT7aNLk8YwjoQ-jtigDIlpzAeiz7Y4FqU=rw-lo', logoText: 'The State of Illinois used Contact Center AI virtual agents to handle more than 140,000 phone and web unemployment inquiries per day. Discover what you can do with our government solutions.' },
// { id: 11, name: 'Digital Transformation', imageUrl: 'https://lh3.googleusercontent.com/5k1Rz_FnJgSXbsYKOJu-yAsmWfmWimKQwZg80W6y15LhfcDxEUrYRCZhNaJl7iadwQFP3we-7XQc=rw-lo', text1: '', text2: 'Top academic institutions choose Google Cloud', logoUrl: 'https://lh3.googleusercontent.com/LiYUUg5SC-NeaGyAtYXSCci_xa9k0vID4f3R_vDIT0CtS7Ij1bAN3aeqir6N7mkuTf1AhVz7tiiR=rw-lo', logoText: 'Georgetown University uses Google Cloud to optimize their operations, accelerate research, and prepare today’s rising leaders for tomorrow’s digital economy. Discover what you can do with our education solutions.  ' },

const Reviews = () => {
  const [selectedName, setSelectedName] = useState(data[0].id);

  const handleNameClick = (id) => {
    setSelectedName(id);
  };

  return (
    <div className="flex flex-col lg:flex-row lg:px-10 py-2 sm:py-4 lg:py-10 mx-2 sm:mx-4 md:mx-6 lg:mx-16">
  <div className=" py-4 sm:py-6 text-lg w-full lg:w-1/4 p-4 lg:ml-10 lg:mr-4">
    <ul className="overflow-auto max-h-80 lg:max-h-full">
      {data.map((item) => (
        <li
          key={item.id}
          className={`cursor-pointer w-full py-2 px-2 ${selectedName === item.id ? 'text-[#38B6FF] bg-white mt-2 rounded-3xl' : 'hover:bg-blue-200 rounded-3xl mt-2 hover:text-[#38B6FF]'}`}
          onClick={() => handleNameClick(item.id)}
        >
          {item.name}
        </li>
      ))}
    </ul>
  </div>

  <div className="flex-1  p-4 py-2 border-4 rounded-lg border-blue-900 lg:ml-0 lg:mr-4 mt-4 lg:mt-0">
    <>
      <div >
        <img
          src={data[selectedName - 1].imageUrl}
          alt={data[selectedName - 1].name}
          className="w-full h-72 min-h-100 mb-4 rounded-xl"
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <div className='px-4 py-4 bg-black text-lg text-white border-2 border-white rounded-xl'>
          <p className="font-bold text-4xl sm:text-3xl">{data[selectedName - 1].text1}</p>
          <Link
  to="/contact"
  className="flex relative   py-2 mt-4 px-6 rounded-md hover:from-blue-700 hover:to-blue-500 transition-all duration-1000 transform  items-center"
>
  <span>Hire Now</span>
  <FaArrowRight className="ml-2 hover:ml-6  transition-all duration-300 transform hover:scale-105" /> {/* Adjust margin-left as needed */}
</Link>
        </div>
        <div className="flex flex-col bg-gray-100 text-black px-2 py-4 rounded-xl mt-4 sm:mt-0">
          <img src={data[selectedName - 1].logoUrl} alt="Logo" className="w-20 h-10" />
          <p className='text-md'>{data[selectedName - 1].logoText}</p>
        </div>
      </div>
    </>
  </div>
</div>

  );
};

export default Reviews;