import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const TextCloud = () => {
  return (
    <section className="flex flex-col items-center justify-center min-h-screen ">
      <h1 className="text-6xl max-lg:text-3xl font-bold  text-center lg:w-2/3 mt-10 mb-10 ">
        <span className="bg-[#38B6FF] bg-clip-text text-transparent">
          Let’s start building
        </span>{" "}
        your tomorrow,
        <br /> today
      </h1>
      <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
        <a href="#" className="inline-flex">
          <Link
            to="/contact"
            className="flex relative bg-[#38B6FF] text-white py-4 px-6 rounded-md  transition-all duration-300 transform items-center"
          >
            <span>Schedule a Call</span>
            <FaArrowRight className="ml-2 hover:ml-6  transition-all duration-1000 transform " />{" "}
            {/* Adjust margin-left as needed */}
          </Link>
        </a>
      </div>
      <div className="mt-8 flex flex-wrap justify-center lg:justify-start max-lg:hidden">
        <div className="w-72 max-lg:w-auto h-2 bg-red-500 inline-block mb-2 lg:mb-0"></div>
        <div className="w-96 max-w-full h-2 bg-blue-500 inline-block mb-2 lg:mb-0"></div>
        <div className="w-72 max-w-full h-2 bg-green-500 inline-block mb-2 lg:mb-0"></div>
        <div className="w-72 max-w-full h-2 bg-yellow-500 inline-block"></div>
      </div>
      <div className="flex flex-col lg:flex-row justify-center lg:justify-between mt-8 w-full lg:w-10/12">
        <div className="pr-0 lg:pr-16 max-w-full lg:max-w-none lg:mr-8">
          <h2 className="text-lg font-bold text-center lg:text-left">
            Work with a trusted partner
          </h2>
          <p className="text-[#38B6FF] text-center lg:text-left">
            Find a partner
          </p>
        </div>
        <div className="text-center max-w-full lg:text-left lg:ml-8">
          <h2 className="text-lg font-bold">
            Run on the industry’s cleanest cloud
          </h2>
          <p className="text-[#38B6FF]">Learn more</p>
        </div>
        <div className="mt-8 lg:mt-0 max-w-full lg:max-w-none lg:ml-0">
          <h2 className="text-lg font-bold text-center lg:text-left">
            Get updates with the Google Cloud newsletter
          </h2>
          <p className="text-[#38B6FF] text-center lg:text-left">Subscribe</p>
        </div>
      </div>
    </section>
  );
};
export default TextCloud;
