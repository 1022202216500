/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import VideoPopup from "./VideoPopup.js";
import { Link } from "react-router-dom";
const HeroSection = () => {
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleIframeClick = () => {
      openVideoPopup();
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener("click", handleIframeClick);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener("click", handleIframeClick);
      }
    };
  }, []);

  const openVideoPopup = () => {
    setVideoPopupOpen(true);
  };

  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };

  return (
    <section className="">
      <div className="grid max-w-screen-xl px-4 py-8 mx-auto gap-4 lg:gap-8 lg:py-16 lg:grid-cols-12">
        <div className="col-span-12 lg:col-span-7 cursor-pointer">
          <div className="bg-white text-[#38B6FF]   p-4 h-auto lg:h-16 max-w-lg rounded-md hover:rounded-lg flex items-center justify-between">
            <p className="text-base ">
              Get <span className="font-bold">$300 in free credits</span> and
              free usage of 20+ products
            </p>
            <span className="rounded-full bg-[#38B6FF] text-white p-2">
              <FaArrowRight className="w-3 h-3 " />
            </span>
          </div>
          <h1 className="max-w-2xl mt-8 mb-4 text-4xl font-bold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-blue-600">
            <span className="">
              {" "}
              Unlock your potential with our
            </span>{" "}
            <span className="text-[#38B6FF]"> digital solutions</span>
          </h1>
          <p className="mb-6 text-black lg:mb-8 md:text-lg lg:text-xl dark:text-black">
            Build with generative AI, deploy apps fast, and analyze data in
            seconds <br />
            all with Peron-Cloud security.
          </p>
          <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
            <a href="#" className="inline-flex">
              <Link
                to="/contact"
                className="flex relative bg-[#38B6FF] text-white py-4 px-6 rounded-md hover:from-green-600 hover:to-blue-500 transition-all duration-300 transform items-center"
              >
                <span>Schedule a Call</span>
                <FaArrowRight className="ml-2 hover:ml-6  transition-all duration-1000 transform " />
              </Link>
            </a>
          </div>
        </div>
        <div
          className="col-span-12 lg:col-span-5 "
          onClick={openVideoPopup}
          style={{ position: "relative" }}
        >
          <div className="lg:flex lg:flex-wrap lg:items-stretch lg:flex-grow">
            <div className="w-full h-auto lg:w-full lg:h-96 relative">
              <iframe
                width="853"
                height="480"
                src="https://www.youtube.com/embed/tCHYMtccduY"
                title="Google Cloud: The new way to cloud"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                className="w-full h-full object-cover shadow-lg rounded-lg border-blue-900 hover:rounded-3xl hover:ease-in-out"
              ></iframe>
              <div
                onClick={openVideoPopup}
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  cursor: "pointer",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      {isVideoPopupOpen && <VideoPopup onClose={closeVideoPopup} />}
    </section>
  );
};

export default HeroSection;
