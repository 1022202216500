import React from 'react';

const CloudBox = () => {
  return (
<div className='pb-16' >
  <div className='flex justify-between items-center max-lg:flex-col max-lg:mx-2'>
    <div className='w-1/2 max-lg:w-auto mt-6'>
      <p className='text-5xl max-lg:w-auto font-bold font-sans text-white ml-20 max-lg:text-3xl max-lg:ml-2'>
        Cloud computing, ready for business
      </p>
    </div>
    <span className='w-1/2 max-lg:w-auto mt-10 max-lg:mt-2'>
      <p className='ml-8 max-lg:ml-2 text-2xl max-lg:text-lg text-white mb-6'>
        Whether you’re migrating or already in the cloud, we’ll help you modernize and digitally transform your business.
      </p>
      <button className=' transition-all duration-1000 bg-[#38B6FF] px-4   hover:text-white py-2 rounded-md ml-8 max-lg:ml-2'>
        Talk To Us
      </button>
    </span>
  </div>
  <div className='flex justify-around mt-8 mx-16 max-lg:mx-2 max-lg:flex-col'>
    <div className='h-auto px-6 py-10 border text-white border-b-2 rounded-lg mx-4 w-1/4 max-lg:w-auto max-lg:mt-6 max-lg:h-auto   text-left hover:bg-white hover:text-black transition-all duration-1000'>
      <p className=' hover:text-black font-bold text-xl '>
        Run and build your apps, anywhere
      </p>
      <p className=' hover:text-black text-xl mt-4'>
        Avoid vendor lock-in and speed up development with Google Cloud’s commitment 
        to open source, hybrid, 
        and multicloud.
      </p>
    </div>

    <div className='h-auto px-6 py-10 border border-b-2 rounded-lg mx-4 w-1/4  text-left  max-lg:w-auto max-lg:mt-6 max-lg:h-auto   hover:bg-white hover:text-black text-white transition-all duration-1000'>
      <p className=' font-bold text-xl'>
        Make smarter decisions with the leading data platform
      </p>
      <p className='text-xl mt-4'>
        Give anyone on your team access to business insights with advanced machine learning and analytics.
      </p>
    </div>

    <div className='box border px-6 py-10 border-b-2 rounded-lg mx-4 w-1/4  text-left max-lg:w-auto  max-lg:mt-6 max-lg:h-auto   hover:bg-white hover:text-black text-white transition-all duration-1000'>
      <p className=' font-bold text-xl'>
        Protect what's important
      </p>
      <p className=' text-xl mt-4'>
        Help defend your data and apps against threats and fraudulent activity with the same security technology Google uses.
      </p>
    </div>

    <div className='box border px-6 py-10  border-b-2 rounded-lg mx-4 w-1/4  text-left max-lg:w-auto max-lg:mt-6  max-lg:h-auto   hover:bg-white hover:text-black text-white transition-all duration-1000'>
      <p className=' font-bold text-xl'>
        Transform how your teams collaborate—from anywhere
      </p>
      <p className=' text-xl mt-4'>
        Integrate video calling, email, chat, and document collaboration in one place.
      </p>
    </div>
  </div>
</div>

  );
};

export default CloudBox;
