import React from 'react';

function Textsection() {
  return (
    <div className='flex justify-between items-center max-lg:flex-col px-8 py-2 '>
        <div className='w-1/2 max-lg:w-auto'>
        <p className=' text-4xl max-lg:w-auto font-bold font-sans  ml-20 max-lg:ml-2'>
          Solve your business problems with{' '}
          <div>
            our <span className='bg-[#38B6FF] bg-clip-text text-transparent'>industry solutions</span>
          </div>
        </p>
        </div>
        <span className='w-1/2 max-lg:w-auto mt-10 '>
          <p className=' ml-8 max-lg:ml-2 text-xl   mb-6'>
            From improving retail <a className='underline text-blue-500' href='#'>product discovery</a> to detecting <a className='underline text-blue-500' href='#'> bank fraud,</a>
            <div>our industry solutions tackle your biggest challenges.</div> 
          </p>
          <button className='bg-[#38B6FF] text-white max-lg:px-2 px-8 hover:bg-blue-800 py-4 rounded-md ml-8 max-lg:ml-2 '>
            Request a demo
          </button>
      </span>
    </div>
  );
}

export default Textsection;
