import React from 'react'

function Boxesofvideos() {

    const boxesData = [
        {
          icon: '📦', // Add your actual icon or image source
          heading: 'Get code assistance for writing apps, calling APIs, or querying data',
          description: 'Try Duet AI ',
        },
        {
          icon: '🎉',
          heading: 'Get free generative AI training ',
          description: 'Start free training',
        },
        {
          icon: '🚀',
          heading: 'Access free offers for common AI use cases ',
          description: 'View 10 free AI tools',
        },
      ];
    
  return (
    <div className='bg-black relative text-white p-6'>
      <div className="w-full md:flex md:flex-wrap justify-center">
        {boxesData.map((box, index) => (
          <div key={index} className="w-full md:w-96 ml-4 max-lg:ml-0 md:ml-8 mb-8 p-4 shadow-lg rounded-lg transition-all duration-300 hover:shadow-xl bg-gray-800">
            <div className="text-2xl mb-2">{box.icon}</div>
            <h2 className="text-xl  mb-2">{box.heading}</h2>
            <p className='text-blue-400 text-lg'>{box.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Boxesofvideos