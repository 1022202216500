// src/components/ThreeBoxesPage.js

import React from "react";

const ContactSection = () => {
  return (
<>
  <div className="flex pb-20  max-lg:flex-col bg-gray-100">
    <div className="container px-2 ml-3 w-5/12 max-lg:w-auto ">
      <div className="block rounded-lg bg-[hsla(0,0%,100%,0.7)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,5%,0.7)] dark:shadow-black/20 md:py-16 md:px-12 mt-12 backdrop-blur-[30px]">
        <div className="mx-auto max-w-[700px]">
          <h2 className="text-3xl font-bold mb-6 text-center text-primary dark:text-primary-400">
            Book a Free Consultant
          </h2>
          <p className="text-center text-neutral-500 dark:text-neutral-200 mb-6">
            Book a slot with our senior team to discuss your project and see how we can apply our expertise to your idea.
          </p>
          <button
            onClick={() => {
              window.location.href = "https://calendly.com/abdulbasit335";
            }}
            className="inline-block w-full rounded-lg bg-blue-700 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] lg:mb-0"
          >
            Schedule a Call
          </button>
        </div>
      </div>
    </div>
    
    <section className="flex mx-2 max-lg:mx-4 max-lg:w-auto max-w-screen w-7/12 items-center mt-6 font-poppins dark:bg-gray-800">
      <div className="justify-center flex-1  mx-auto  max-lg:px-0 max-lg:py-0  px-9 py-9 ">
     
        <div className="px-9 max-lg:px-0 py-6 rounded shadow-md dark:bg-gray-700 bg-gray-50">
          <form action="" className="">

        <div className="flex flex-wrap mb-6">
          <div className="w-full px-3 md:w-1/2 md:mb-0">
            <label
              htmlFor="firstname"
              className="block mb-2 font-bold text-gray-700 uppercase dark:text-gray-400"
            >
              First Name
            </label>
            <input
              type="text"
              placeholder="first name"
              required=""
              className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-100 border rounded lg:mb-0 dark:text-gray-400 dark:border-gray-800 dark:bg-gray-800 "
            />
          </div>
          <div className="w-full px-3 md:w-1/2 md:mb-0">
            <label
              htmlFor="firstname"
              className="block mb-2 font-bold text-gray-700 uppercase dark:text-gray-400"
            >
              Last Name
            </label>
            <input
              type="text"
              placeholder="last name"
              required=""
              className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-100 border rounded dark:placeholder-gray-500 lg:mb-0 dark:text-gray-400 dark:border-gray-800 dark:bg-gray-800 "
            />
          </div>
        </div>
        <div className="px-3 mb-6">
          <label
            htmlFor="firstname"
            className="block mb-2 font-bold text-gray-700 uppercase dark:text-gray-400"
          >
            Email
          </label>
          <input
            type="text"
            placeholder="abc@gmail.com"
            required=""
            className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-100 border rounded dark:placeholder-gray-500 dark:text-gray-400 dark:border-gray-800 dark:bg-gray-800 "
          />
        </div>
        <div className="px-3 mb-6">
          <label
            htmlFor="firstname"
            className="block mb-2 font-bold text-gray-700 uppercase dark:text-gray-400"
          >
   Company
          </label>
          <input
            type="text"
            placeholder="Name of the company"
            required=""
            className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-100 border rounded dark:placeholder-gray-500 dark:text-gray-400 dark:border-gray-800 dark:bg-gray-800 "
          />
        </div>
        <div className="">

<div className="px-3 mb-6 ">
  <label
    htmlFor="communicationChannel"
    className="block mb-2 font-bold text-gray-700 uppercase dark:text-gray-400"
  >
    Communication Channel
  </label>
  <div className="flex items-center space-x-4">
    <label className="inline-flex items-center">
      <input
        type="radio"
        name="communicationChannel"
        value="Email"
        className="form-radio text-blue-600"
      />
      <span className="ml-2">Email</span>
    </label>
    <label className="inline-flex items-center">
      <input
        type="radio"
        name="communicationChannel"
        value="PhoneCall"
        className="form-radio text-blue-600"
      />
      <span className="ml-2">Phone Call</span>
    </label>
    <label className="inline-flex items-center">
      <input
        type="radio"
        name="communicationChannel"
        value="Whatsapp"
        className="form-radio text-blue-600"
      />
      <span className="ml-2">Whatsapp</span>
    </label>
    <label className="inline-flex items-center">
      <input
        type="radio"
        name="communicationChannel"
        value="Telegram"
        className="form-radio text-blue-600"
      />
      <span className="ml-2">Telegram</span>
    </label>
  </div>
</div>


        <div className="px-3 mb-6">
          <label
            htmlFor="firstname"
            className="block mb-2 font-bold text-gray-700 uppercase dark:text-gray-400"
          >
            Message
          </label>
          <textarea
            type="message"
            placeholder="Share Your Queries"
            required=""
            className="block w-full px-4 leading-tight text-gray-700 bg-gray-100 rounded dark:placeholder-gray-500 py-7 dark:text-gray-400 dark:border-gray-800 dark:bg-gray-800 "
            defaultValue={""}
          />
        </div>
        </div>
        <div className="px-3">
          <button className="px-4 py-2 font-medium text-gray-100 bg-blue-600 rounded shadow hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-700">
            Send
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
        </div>
  
</>

  );
};

export default ContactSection;
